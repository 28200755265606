<template>
    <div class="cards-container">
        <div class="cards-sub-container">
            <div class="card">
                <img class="card-image" :src="require('@/assets/setings.svg')" alt="">
                <h3 class="title">Область</h3>
                <p class="text">
                    Применяется в конвейерах ленточного типа в подземных выработках шахт, а также промышленных и горно-технологических объектах.
                </p>
            </div>

            <div class="card">
                <img class="card-image" :src="require('@/assets/(c).svg')" alt="">
                <h3 class="title">Правообладатель</h3>
                <p class="text">
                    Все права на реализацию программного обеспечения принадлежат ООО “Трансмаш”.
                </p>
            </div>

            <div class="card">
                <img class="card-image" :src="require('@/assets/cal.svg')" alt="">
                <h3 class="title">Срок поддержки</h3>
                <p class="text">
                    1 год после полной установки на вашем производстве.
                </p>
            </div>
            <div class="card">
                <img class="card-image" :src="require('@/assets/dollar-sign.svg')" alt="">
                <h3 class="title">Цена</h3>
                <p class="text">
                    Рассчитывается индивидуально. Оставьте заявку или свяжитесь с нами.
                </p>
            </div>
        </div>

    </div>
</template>

<script>

export default {
  name: "ProductCards",
  data() {
    return {
     
    }
  },

  methods: {

    
  },
  

}

</script>

<style scoped>

.cards-container{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 4vh;
}

.cards-sub-container{
    display: flex;
    flex-direction: row;
    max-width: 1300px;
    column-gap: 2vh;
    width: 100%;
    row-gap: 2vh;
    justify-content: center;
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 25%;
    background: var(--slider-lenta-bg);
    border-radius: 20px;
    color: var(--text);
    padding-bottom: 2vh;
    position: relative;
    min-height: 22vh;
}

.card-image{
    position: absolute;
    top: 1vh;
    right: 1vh;
    width: 10vh;
}

.title{
    font-size: clamp(16px, 2.2vh, 50px);
    font-weight: 600;
    padding-left: 2vh;
    padding-top: 8vh;
}

.text{
    font-size: clamp(14px, 1.8vh, 50px);
    padding: 1vh 2vh 0 2vh;
}

@media (max-width: 1300px){
    .cards-sub-container{
        flex-wrap: wrap;

    }

    .cards-container{
        width: calc(100% - 4vh);
        margin-left: 2vh;
    }

    .card{
        min-width: 320px;

    }

} 



@media (max-width: 800px){
    .cards-sub-container{
        flex-direction: column;
        row-gap: 2vh;
    }
    .card{
        width: 100%;
        height: 15vh;
    }
} 

</style>