<template>
  <div class="carousel">
    <h2 class="title">
      Скриншоты
    </h2>
    <div :class="isFullscreen ? 'carousel-inner-full' : 'carousel-inner'" v-if="isImage" >
      <CarouselItem v-for="(slide, index) in slides.length == 0 ? 1 : slides"
                    :slide="slide"
                    :currentSlide="currentSlide"
                    :index="index"
                    :direction="direction"
                    :key="index"
                    :isFullscreen="isFullscreen"
      />
      <CarouselControls v-if="slides.length > 1" @prev="prev"
                        @next="next"
      >

      </CarouselControls>
      <button class="fullscreen-btn" @click="goFullScreen">
        <svg v-if="isFullscreen" width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 15.5H4C4.92931 15.5 5.39397 15.5 5.78036 15.5769C7.36712 15.8925 8.60751 17.1329 8.92314 18.7196C9 19.106 9 19.5707 9 20.5V21" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.5 21L15.5 20.5V20.5C15.5 19.5707 15.5 19.106 15.5769 18.7196C15.8925 17.1329 17.1329 15.8925 18.7196 15.5769C19.106 15.5 19.5707 15.5 20.5 15.5L21 15.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M21 9H20V9C19.535 9 19.3025 9 19.1067 8.98069C17.2081 8.79343 15.706 7.29144 15.5187 5.39289C15.4993 5.19706 15.4993 4.96457 15.4993 4.49959L15.4992 3" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8.99959 3L8.99966 4C8.99972 4.92905 8.99975 5.39357 8.92297 5.77986C8.60749 7.36693 7.3669 8.6076 5.77986 8.92319C5.39357 9 4.92905 9 4 9V9L3 9" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg v-else width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 21H8C7.07069 21 6.60603 21 6.21964 20.9231C4.63288 20.6075 3.39249 19.3671 3.07686 17.7804C3 17.394 3 16.9293 3 16V15.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M21 15.5V16V16C21 16.9293 21 17.394 20.9231 17.7804C20.6075 19.3671 19.3671 20.6075 17.7804 20.9231C17.394 21 16.9293 21 16 21H15.5" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M15.5 3H16.5V3C16.965 3 17.1975 3 17.3933 3.01931C19.2919 3.20657 20.794 4.70856 20.9813 6.60711C21.0007 6.80294 21.0007 7.03543 21.0007 7.50041L21.0008 9" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M3.00041 9L3.00034 8C3.00028 7.07095 3.00025 6.60643 3.07703 6.22014C3.39251 4.63307 4.6331 3.3924 6.22014 3.07681C6.60643 3 7.07095 3 8 3V3L9 3" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

      </button>
    </div>
  </div>
</template>

<script>
import CarouselItem from "@/components/Slider/CarouselItem.vue";
import CarouselControls from "@/components/Slider/CarouselControls.vue";

export default {
  name: "CarouselLenta",
  components: {
    CarouselControls,
    CarouselItem
  },
  data() {
    return {
      currentSlide: 0,
      slideInterval: null,
      direction: 'right',
      isImage: true,
      imgWhite: require('@/assets/icons/imgWhite.svg'),
      imgBlue: require('@/assets/icons/imgBlue.svg'),
      videoBlue: require('@/assets/icons/videoBlue.svg'),
      videoWhite: require('@/assets/icons/videoWhite.svg'),
      slides: [
        require('@/assets/sliderScrin1.svg'),
        require('@/assets/sliderScrin2.svg'),
        require('@/assets/sliderScrin3.svg'),
        require('@/assets/sliderScrin4.svg'),
        require('@/assets/sliderScrin5.svg'),
        require('@/assets/sliderScrin6.svg'),
      ],
      isFullscreen: false
    };
  },
  methods: {
    setCurrentSlide(index) {
      this.currentSlide = index;
    },
    prev() {
      const index = this.currentSlide > 0
        ? this.currentSlide - 1
        : this.slides.length - 1;
      this.setCurrentSlide(index);
      this.direction = "left";
    },
    next() {
      const index = this.currentSlide < this.slides.length - 1 ? this.currentSlide + 1 : 0;
      this.setCurrentSlide(index);
      this.direction = "right";
    },
    goFullScreen() {
      if (this.isFullscreen) {
        this.isFullscreen = false
      }else{
        this.isFullscreen = true
      }
    }


  },
  created() {
    // console.log(this.slides);
  }
}
</script>

<style scoped>
.carousel {
  padding: 4vh 0;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 20px;
  border-image: linear-gradient(to bottom, rgba(20, 20, 20, 0.1), #ccc, rgba(20, 20, 20, 0.1)) 1 100%;
  border-style: var(--border-style-carousel-lenta);
  border-width: var(--border-width-carousel-lenta);
  box-shadow: var(--carousel-lenta-box-shadow);
  position: relative;
  flex-direction: column;
  /* background: var(--about-bg); */

}

.title{
  text-align: center;
  font-size: clamp(30px, 4.8vw, 50px);
  font-family: geometria_extralight;
  text-transform: uppercase;
  padding-bottom: 2vh;
  color: var(--text);
}

.carousel-inner {
  position: relative;
  border-radius: 20px;
  max-width: 1300px;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  background: var(--slider-lenta-bg);

}

.carousel-inner-full{
  position: fixed;
  background: black;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.carousel-inner:fullscreen {
  width: 100%;
  height: 100%;
}

.fullscreen-btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 11;
  background-color: white;
  border-radius: 12px;
  padding: 1vh;
  box-shadow: 57px 108px 49px rgba(0, 0, 0, 0.01), 14px 27px 30px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.04), 4px 7px 17px rgba(0, 0, 0, 0.04);

}

.fullscreen-btn-fixed{
  position: fixed;
  bottom: 2vh;
  right: 2vh;
  z-index: 11;
  bottom: 20px;
  right: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  background-color: white;
  border-radius: 12px;
  padding: 1vh;
}

.fullscreen-icon {
  width: 30px;
  height: 30px;
}

.buttons-container{
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 2vh;
  column-gap: 1vh;
  
}



.buttonImg{
  height: 2vh;
  width: 2vh;
}

@media (max-width: 1300px){
    .carousel{
        width: calc(100% - 4vh);
        margin-left: 2vh;
    }

} 

@media (max-width: 800px){
    .carousel-inner{
      height: 40vh;
    }

} 

</style>