<template>
    <div class="sefetyContainer">
        <Header
                style="position: relative"
                :theme="$store.state.theme == 'light' ? 'dark' : 'light'"
        />

        <div class="sefetySubContainer">
            <DocumentationAboutPage
                type="pdf"
                :isTitle="true"
                title="Документация"
                :arrayElement="pdfArrayTitels"
                :id="pdfArrayId"
            />
            <DocumentationAboutPage
                type="certificate"
                title="Сертификаты"
                :isTitle="false"
                :arrayElement="certificateArrayTitels"
                :isDarkTheme="true"
                :id="certificateArrayId"
            />
        </div>

        <Footer/>

    </div>

</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import DocumentationAboutPage from "@/components/DocumentationAboutPage.vue";
import {HTTP} from "@/plugins/http-common";
import {useHead} from "@vueuse/head";



export default {
    name:'IndustrialSafetyPage',
    components: {
        DocumentationAboutPage,
        Header,
        Footer,
    },
    data() {
        return {
            pdfArrayTitels: [],
            pdfArrayId: [],
            certificateArrayTitels: [],
            certificateArrayId: [],
        }
    },

    methods: {
        
        
    },
    created() {

        HTTP.get('/document/get_all_document').then(response => {
            this.pdfArrayTitels = response.data.data
            for (let i = 0; i < response.data.data.length; i++) {
              this.pdfArrayId.push(response.data.data[i].id)
            }
      }).catch(err => {

      })
      useHead({
        title: `Промышленная безопасность - ООО «Трансмаш»`,
        meta: [
          {
            name: `description`,
            content: `Мы - производитель горно-шахтного оборудования, который предлагает своим клиентам широкий выбор высококачественных товаров.`
          },
          {
            name: `keywords`,
            content: `описание, о нас, история компании, разработка, высокотехнологичная продукция, услуги, сервис, трансмаш, оборудование для шахт, горно-шахтное оборудование`
          }
        ]

      })
      HTTP.get('/certificate/get_all_certificate').then(response => {
            this.certificateArrayTitels = response.data.data
            for (let i = 0; i < response.data.data.length; i++) {
              this.certificateArrayId.push(response.data.data[i].id)
            }
      }).catch(err => {

      })
    },
  updated() {
  }


}

</script>

<style scoped>
.sefetyContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.sefetySubContainer{
    display: flex;
    flex-direction: column;
}


</style>