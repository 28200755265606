<template>
  <div class="container">
    <div class="sub-container">
      <h2>
        {{$store.state.lang == 'ru-RU' ? 'РЕКОМЕНДУЕМОЕ ИЗ ТОЙ ЖЕ КАТЕГОРИИ' : 'RECOMMENDED FROM THE SAME CATEGORY'}}
      </h2>
      <div class="container-cards">
<!--        <div class="cards" v-for="(element, index ) in recommendedID">-->
<!--          <div>-->
<!--            <div-->
<!--                class="blurImage"-->
<!--                :style="{backgroundImage: `url('` + recommendedImage[index] + `')`}"-->
<!--            >-->

<!--            </div>-->
<!--            <div class="image"-->
<!--                 :style="{backgroundImage: `url('` + recommendedImage[index] + `')`}"-->
<!--            ></div>-->
<!--          </div>-->

<!--          <p class="text">-->
<!--            {{recommendedText[index]}}-->
<!--          </p>-->

<!--          <CustomButton :link="`/product/${recommendedID[index]}`" :button-text="$t('Buttons.more')"/>-->

<!--        </div>-->
<!--      </div>-->
        <CardCarousel :card-image="recommendedImage"
                      :card-text="recommendedText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomButton from "@/components/ui/CustomButton.vue";
import CustomCard from "@/components/ui/CustomCard.vue";
import CardCarousel from "@/components/CardCarousel.vue";

export default {
  name: "RecommendedProduct",
  components: {CustomCard, CustomButton, CardCarousel},
  props: {
    recommendedID: {
      type: Array,
      default: [1]
    },
    recommendedImage: {
      type: Array,
      default: ['No image']
    },
    recommendedText: {
      type: Array,
      default: ['Абоба']
    },
  }
}
</script>

<style scoped>
    .container {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: start;
      color: var(--text);
      background: var(--about-bg);
      padding-top: 50px;
      /*scroll-snap-align: center;*/
    }

    .sub-container {
      width: 1300px;
      display: flex;
      flex-direction: column;
      /*justify-content: center;*/
    }

    h2 {
      font-size: clamp(30px, 4.8vw, 50px);
      font-family: geometria_extralight;
      text-transform: uppercase;
      text-align: center;
      font-weight: 400;
    }

    .container-cards {
      display: flex;
      justify-content: center;
      column-gap: 20px;
    }

    .cards {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 15px;
      height: 300px;
      background: var(--card-bg);
      box-shadow: var(--card-box-shadow);
      width: 350px;
    }

    .blurImage {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      /*opacity: 0.2;*/
      position: absolute;
      filter: blur(20px);
      opacity: 0.6;
    }

    .image {
      width: 100%;
      height: 280px;
      overflow: hidden;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
</style>