<template>
    <div class="wrapper">
        <img 
            :src="img1" 
            alt="" 
            :class="{'back-image': pickedIndex == 1, 'front-image': pickedIndex == 0}"
        />
        <img 
            :src="img2" 
            alt="" 
            :class="{'back-image': pickedIndex == 0, 'front-image': pickedIndex == 1}"
        />
        
        <div class="dots-container"
        @mouseenter="() => {autoSkip = false}"
        @mouseleave="() => {autoSkip = true}"
        >
            <div 
                class="dot"
                v-for="(dot, index) in slides"
                :key="index"
                :class="{'picked-dot': index == pickedIndex}"
                v-on:click="setNewIndex(index)"
            >
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ImageStackLenta',

        props: {
            img1: {
                type: String,
                required: true
            },
            img2: {
                type: String,
                required: true
            }
        },

        data(){
            return {
                pickedIndex: 0,
                slides: ['1', '2'],
                autoSkip: true,
                isDarkTheme: JSON.parse(localStorage.getItem('theme'))
            }
        },
        
        methods: {
            setNewIndex(newIndex){
                this.pickedIndex = newIndex
            },
        },

        mounted() {
            setInterval(() => {
                if(this.autoSkip){
                    this.pickedIndex == 0 ? this.pickedIndex = 1 : this.pickedIndex = 0;
                }
            }, 3500)
        },
    }
</script>

<style scoped> 
    img {
        width: 80%;
        

        position: absolute;
        transition: 0.3s;
    }

    .front-image {
        margin: 0px 0px 50px 50px;

        z-index: 1;

        /* box-shadow: 1px 3px 10px rgb(100,100,100,0.3); */
    }

    .back-image {
        margin: 100px 150px 10px 0px;

        transform: scale(0.8);
        filter:grayscale(1) brightness(0.9) blur(2px);
        opacity: 0.8;
       
    }

    .wrapper {
        width: 100%;
        height: 100%;

        position: relative;

        display: flex;
        justify-content: center;
    }

    .dots-container {
        display: flex;
        /* justify-content: space-between; */
        align-items: center;

        position: absolute;
        bottom: 0;
    }

    .dot {
        width: 12px;
        height: 12px;

        background-color: #ccc;

        border-radius: 100%;
        margin-left: 10px;

        cursor: pointer;

        transition: 0.15s;
    }

    .picked-dot{
        width: 16px;
        height: 16px;
        background-color: var(--blue);
        transition: 0.15s;
    }
</style>