<script setup>
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n({useScope: 'global'});
</script>

<template>
    <div class="container"
         :class="{'phone-container': phoneMenu}"   >
        <div 
            class="sub-container"
            :class="{'phone-sub-container': phoneMenu}"
        >
        <div @click="$router.push('/')"             
        :class="$store.state.lang == 'ru-RU' ? 'logo_ru' : 'logo_en'"></div>

            <nav  class="sub-container-reverse"
                :class="{'phone-header-reverse': phoneMenu}"
            >
                <div
                    @mouseover="openDropdown" 
                    @mouseleave="closeDropdown"
                    class="header-about-button"
                    v-bind:style="{ color: theme == `light` ? 'white' : 'black'}"
                >
                    {{$t('Header.about')}}
                    <img 
                        v-bind:style="{ filter: theme == `light` ? 'invert(1)' : ''}" 
                        class="arrowAbout" 
                        src="@/assets/icons/arrow.png" 
                        alt=""
                    >
                    <transition name="fade">
                        <ul class="dropDownContainer" @mouseleave="closeDropdown" v-show="isOpenDropdown">
                            <li class="dropdown-item" @click="$router.push('/about')">
                                {{$t('Header.about_company')}}
                            </li>
                            <li class="dropdown-item" @click="$router.push('/safety')">
                                {{$t('Header.industrial_safety')}}
                            </li>
                        </ul>
                    </transition>
                </div>
                <a v-if="phoneMenu" class="dropdown-item" @click="$router.push('/about')">
                    {{$t('Header.about_company')}}
                </a>
                <a v-if="phoneMenu" class="dropdown-item" @click="$router.push('/safety')">
                    {{$t('Header.industrial_safety')}}
                </a>
                
                <a 
                @click="clearCategory"  
                class="header-button"
                :class="{'phone-header-button': phoneMenu}"
                v-bind:style="{ color: theme == `light` ? 'white' : 'black'}"
                >
                    {{$t('Header.catalog')}}
                </a>

                <a 
                @click="$router.push('/news')"     
                class="header-button"
                :class="{'phone-header-button': phoneMenu}"
                v-bind:style="{ color: theme == 'light' ? 'white' : 'black'}"
                >
                    {{$t('Header.news')}}
                </a>

                <a 
                @click="$router.push('/contacts')" 
                class="header-button"
                :class="{'phone-header-button': phoneMenu}"
                v-bind:style="{ color: theme == 'light' ? 'white' : 'black'}"
                >
                    {{$t('Header.contacts')}}
                </a>

                <a 
                @click="$router.push('/vacancy')" 
                class="header-button"
                :class="{'phone-header-button': phoneMenu}"
                v-bind:style="{ color: theme == 'light' ? 'white' : 'black'}"
                >
                    {{$t('Header.vacancy')}}
                </a>
                
                <div class="header-search"
                :class="{'phone-header-search': phoneMenu}"

                >
                    <input type="text" :placeholder="$t('Header.search')"
                        v-model="search"
                        @focus="focused = true"
                        @blur="focused = false"
                        :style="theme === 'light'
                        ? {
                            border: '1px solid black'
                        }
                        : {
                            border: '1px solid black'
                        }"


                        @input="(e) => { onInputMethod(e) }"

                        class="search"
                        :class= "{activeInput: search.length >= 1 && message_arr.length > 0 && focused == true}"
                        name="message"
                        id="message-input"
                    >
                        
                    <div class="search-list-container"
                    :class= "{  oneSearch:  message_arr.length === 1,
                                twoSearch: message_arr.length === 2,
                                searchNone: focused == false || message_arr.length === 0

                            }"
                    :style="theme === 'light'
                    ? {
                        color: `white`,
                        background: `var(--search-bg)`,
                        // border: `1px solid white`
                    }
                    : {
                        color: `black`,
                        background: 'white',
                        // border: `1px solid black`
                    }"
                    >
                        <p class="search-list"
                        
                        v-for="(message, index) in message_arr"
                        >
                            <router-link :to="message.Route +  `?id=` +  message.id"
                                        @click="message_arr = [] , this.phoneMenu = false, this.search = ''"
                                        
                            >

                                <p class="message-title"
                                >
                                    {{ message.title }}
                                </p>

                                <p class="message-category">
                                    {{ message.category }}
                                </p>
                            </router-link>
                                                
                        
                        </p>
                    </div>
                    
                </div>
            </nav>
            

            <div style="display: flex; align-items: center; gap: 32px;"
                :class="{'header-mini-buttons': !phoneMenu}">
                <ThemeSwitch    :theme="phoneMenu ? $store.state.theme : theme"  />
                <LanguageSwitch :theme="phoneMenu ? $store.state.theme : theme"  />
            </div>
           

            <div class="burger-button" @click="switchPhoneMenu()"
            
            >
                <button class="openMenu" 
                    v-if="!phoneMenu"
                    :style="{filter: theme == 'light' ? 'invert(1)' : 'invert(0)'}"
                    >

                </button>
                <button class="closeMenu" v-else></button>
            </div>

            <div 
                class="adminHeader"
                v-if="user == 'admin' && token !== ''"
            >
                <div class="user">Admin</div>
                <button @click="signOut()">Выйти</button>
            </div>
        </div>
        <!-- <Burger></Burger> -->
    </div>
</template>

<script>

    import LanguageSwitch from '@/components/LanguageSwitch.vue';
    import ThemeSwitch from '@/components/ThemeSwitchButton.vue';
    import Burger from '@/components/BurgerMenu.vue';
    import lightIcon from '@/assets/icons/Union.svg';
    import darkIcon from '@/assets/icons/search.svg';

    // import { state } from "@/socket.js";
    
    // import { socket } from "@/socket";
    import {HTTP} from "@/plugins/http-common";
    import store from "@/store";
   
    let movewait;
	
    export default {
        name: 'Header',

        data() {
            return {
                user: 'user',
                token: "",
                phoneMenu: false,
                search: '',
                message_arr: [1],
                focused: false,
                isOpenDropdown: false,
                selectedItemId: null,
                items: [
                    { id: 1, name: "Item 1" },
                    { id: 2, name: "Item 2" },
                    { id: 3, name: "Item 3" },
                ],
            }
        },

        components: {
            LanguageSwitch,
            ThemeSwitch,
            Burger
        },

        props: {
            theme: {
                type: String,
                default: "light",
            },
            page : {
                type: String,
                default: 'normal'
            }
        },

        methods: {
            signOut(){
                localStorage.setItem('user', 'user')
                localStorage.setItem('token', '')
                window.open("/", "_self");
            },

            clearCategory(){
                window.location.href = "/catalog";
                // this.$emit('clearCategory')
                // console.log("123");
            },

            switchPhoneMenu(){
                this.phoneMenu = !this.phoneMenu
            },

            switchPhoneMenuRout(){
                this.phoneMenu = !this.phoneMenu
            },


            openDropdown() {
            this.isOpenDropdown = true;
            },
            closeDropdown() {
            this.isOpenDropdown = false;
            },
            


            //
            // connect() {
            //     socket.connect();
            // },
            // disconnect() {
            //     socket.disconnect();
            // },
           
            onInputMethod(e){
                if(typeof movewait != 'undefined'){
                    clearTimeout(movewait);
                }
                // Данная переменная нужна для того чтобы заполнять элемент внутри setTimeout
                let thisElement = this
               
                movewait = setTimeout(function(){
                    
                    // Проверяем значения из инпута, если оно больше 0 тогда делаем HTTP запрос
                    if(e.target.value.length > 1 ) {
                        // HTTP запрос для поиска (передаем данные из инпута)
                        HTTP.post(`/search/search/${e.target.value}`, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        })
                            .then(response => {
                                // Заполняем массива данными
                                for(let i = 0; i < response.data.body.length; i++) {
                                    thisElement.message_arr[i] = response.data.body[i]
                                }
                                console.log(response.data.body);
                                
                                }).catch((err) => {
                            // console.log(err)
                            // console.log('error')
                        })
                    }
                }
                ,200);

                //на всякий случай очищаем массив если введено меньше двух символов 
                if(this.search.length < 2 ) {
                    this.message_arr = []
                    
                }
            }
        
        },
        computed: {
            inputStyle() {
            if (this.theme === 'light') {
                return {
                background: `url(${lightIcon}) no-repeat 8px, var(--search-bg)`,
                border: '1px solid black',
                };
            } else {
                return {
                background: `url(${darkIcon}) no-repeat 8px, var(--search-bg)`,
                border: '1px solid black',
                };
            }
            },
        },
        updated() {
            // console.log(this.theme)
            // console.log(this.message_arr)
        },

        mounted() {
            store.commit('checkAdminStatus')
            // Очищаем массив при маунте страницы
            this.message_arr = []
            if(localStorage.user) {
              this.user = localStorage.user;
              this.token = localStorage.token
            }
            // socket.on('response', function (message) {


            //     message = JSON.parse(message);
            //     this.message_arr = message.body
            //     switch (message.message) {
            //         case 'search':
            //             console.log('Получен поисковой ответ ', message)
                       
            //             console.log('Результат ', message.body)

            //             this.message_arr = message.body

            //             console.log((this.message_arr[0]).title)
            //             break;
                
            //         default:
            //             break;
            //     }
                
            // });
             
            // for(let i = 0; i < message.length; i++){
            //         this.message_arr[i] = {
            //             title: JSON.parse(message[i]).message,
                    
            //         }
            //     }
        },  

       
    }
</script>

<style scoped>

    @keyframes smooth-down {
        0% {
            transform: translateY(-15px);
            opacity: 0;
        }

        100% {
            transform: translateY(0px);
            opacity: 1;
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.3s;
    }
    .fade-enter, .fade-leave-to {
    opacity: 0;
    transition: 0.2s;
        
    }

    .dropDownContainer{
        display: flex;
        flex-direction: column;
        position: fixed;
        margin-top: 13.5vh;
        height: 10vh;
        width: 8vw;
        row-gap: 1vh;
        z-index: 1000;
        list-style-type:  none;
    }

    .dropdown-item{
        cursor: pointer;
        transition: 0.3s;
    }

    .dropdown-item:hover{
        transform: scale(1.03);
        transition: 0.3s;
    }

    .arrowAbout{
        height: 1.3vh;
        top: 2.5vh;
        
    }

    .container {

        animation: smooth-down 0.5s ease-in-out;

        width: 100%;
        height: 78px;

        position: absolute;
        /* position: fixed; */

        top: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x:visible !important;
        z-index: 11;
    }

    

    .sub-container {
        width: 1300px;
        height: 75px;
        padding-left: 48px;
        padding-right: 48px;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sub-container-reverse {
        width: 900px;

        padding-left: 48px;
        padding-right: 48px;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }


    .logo_ru, .logo_en {
        width: 220px;
        height: 48px;
        background-image: url(../assets/transmash_logo.svg);

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        background-color: radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,212,255,0) 100%);
        cursor: pointer;

        position: relative;
        overflow: hidden;
    }

    .logo_en {
        background-image: url(../assets/transmash_logo_en.png);

    }

    .header-about-button{
        font-size: 16px;
        color: white;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        align-items: center;
        column-gap: 0.5vw;

        transition: 0.3s;
        
    }

  

    .header-button{
        font-size: 16px;
        color: white;

        /* text-shadow: 0px 0px 16px black; */
        cursor: pointer;

        transition: 0.3s;
        
    }

    .header-button:hover {
        transform: scale(1.1);
    }

    .header-search {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        
    }

    .search-list-container{
        /*color: rgb(255, 255, 255);*/
        position: absolute;
        /*background: var(--card-bg);*/
        overflow-x:scroll;
        width: 236px;
        border-radius: 0 0 10px 10px;
        margin-top: 193px;
        border-bottom: none;
        max-height: 155px;
        /*box-shadow: var(--card-box-shadow);*/
        overflow-y: scroll;
        border: 1px solid black;
        
    }

    .searchNone{
        opacity: 0;
       mask-position: none;
    }

    .message-title{
        color: var(--text);
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        padding-right: 20px;
        
    }
   
    .search-list{
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        padding-left: 10px;
        
        font-size: 14px;
    }

  

    
    .oneSearch{
        margin-top: 110px;
    }

    .twoSearch{
        margin-top: 180px;
    }


    .search-list:first-child{
        padding-top: 10px;
    }

    /* .header-search.activeInput {
        background: white;
        background: var(--card-bg);
        margin-top: 10px;
        border-radius: 10px 10px 0 0;
        padding: 5px 4px;
    } */

    .message-category{
        padding-top: 5px;
        color: #73747D;
    }
    .search {
        width: 200px;
        height: 35px;

        /*border: solid .1px var(--text);*/
        border-radius: 15px;
        /*background-color: var(--card-bg);*/
        color: var(--text);
        padding-left: 36px;

        
        background: var(--search-icon) no-repeat 8px, var(--search-bg);
        /* border: '1px solid black'; */

        
        /* background-repeat: no-repeat;
        background-position: center;
        background-position-x: 8px; */

        outline: none;
    }

    

    .activeInput {
        border-radius: 10px 10px 0 0;
        /*box-shadow: var(--card-box-shadow);*/
        
    }

    .adminHeader {
        width: 60px;
        height: 50px;

        display: flex;
        justify-content: space-evenly;
        align-items: center;

        flex-direction: column;
        color: #0182CF;
        margin-left: 32px;
    }

    .adminHeader button {
        border: none;
        background: none;
        color: #0182CF;
        text-decoration: underline;
        cursor: pointer;
        padding: 2px;
    }

    .burger-button {
        display: none;
    }
    a {
        text-decoration: none;
        color: var(--text);
    }
    .phone-container {
        width: 100vw;
        height: 100vh;
        z-index: 99;
        position: fixed !important;
        background-color: var(--default-bg);

        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column !important;
        transition: 0.3s;
    }

    .phone-sub-container {
        display: flex;
        flex-direction: column;
        height: 80%;
    }

    .phone-header-button {
        display: flex !important;
        color: var(--text) !important;
    }

    

    .phone-header-search {
        display: none;
    }

    .phone-header-reverse{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        row-gap: 30px;
        
        
        
        
    }

    .openMenu, .closeMenu {
        width: 24px;
        height: 24px;
        background: none;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        border: none; 
        outline: none;
    }

    .openMenu {
        background-image: url('../assets/icons/more.png');
    }

    .closeMenu {
        background-image: url('../assets/icons/exit.svg');

        filter: invert(var(--invert)) !important;

    }

    @media (max-width: 1320px) {
        /* .burger-button {
            display: flex;
        }
        .header-button {
            color: var(--text) !important;
        }

        .header-search {
            display: none !important;
        }

        .container {
            
        }

        .sub-container {
            display: flex;
            flex-direction: column;
            height: 80%;
        } */

        .header-about-button{
            display: none;
        }

        .phone-header-search {
            display: block !important;
            
        }

        .header-search{
            
        }

        .search-list-container{
            margin-top: 0px;
           
        }

        .oneSearch{
            margin-top: 0px;
        }

        .twoSearch{
            margin-top: 0px;
        }
        
        .header-search {
            display: none;
        }

        .header-button {
            display: none;
        }

        .burger-button {
            display: flex;
        }

        .sub-container-reverse{
            width: 22% !important;
            max-width: 900px !important;
        }
        
    }

    @media (max-width: 880px) {
        .dropdown-item{
          text-align: center;
        }

        .header-mini-buttons {
            display: none !important;
        }

        .sub-container{
            width: 90%;
        }

        
    } 
</style>
