<template>
    <div class="container">
        <div class="sub-container">
            <div class="text-container">
                <h3 class="title">
                    Повышение безопасности
                </h3>
                <p class="text">
                    СМЛК предоставляет возможность дольше работать безаварийно, уменьшая время простоя конвейера из-за аварий, суммируя и показывая всю необходимую информацию для оперативного реагирования.
                </p>
            </div>
            <div class="slider-contaier">
                <video class="img"  autoplay loop muted playsinline>
                    <source src="../../lenta_video/безопастность.webm" type="video/mp4">
                    Ваш браузер не поддерживает видео.
                </video> 
            </div>
        </div>

    </div>  
</template>

<script>
import ImageStackLenta from "@/components/ImageStackLenta.vue";

export default {
  name: "SafetyLenta",
  components: {
        ImageStackLenta
    },
  data() {
    return {
     
    }
  },

  methods: {

    
  },
  

}

</script>

<style scoped>

.container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    /* background: var(--about-bg); */
    padding: 4vh 0;
    color: var(--text);
}

.sub-container{
    display: flex;
    flex-direction: row-reverse;
    max-width: 1300px;
    width: 100%;
    align-items: center;
    justify-content: center;
    column-gap: 4vh;
}

.text-container{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center ;
}

.title{
    font-size: clamp(16px, 2.2vh, 50px);
    font-weight: 600;
    padding-bottom: 2vh;
}

.text{
    font-size: clamp(14px, 1.8vh, 50px);
    padding-top: 2vh;
}

.img{
    max-width: 100%;
    min-width: 320px;
    max-height: 100%;
    object-fit: contain;
    border-radius: 12px;
    box-shadow: 57px 108px 49px rgba(0, 0, 0, 0.01), 14px 27px 30px rgba(0, 0, 0, 0.03), 0px 0px 0px rgba(0, 0, 0, 0.04), 4px 7px 17px rgba(0, 0, 0, 0.04);

}


.slider-contaier{
    width: 50%;
}

@media (max-width: 1300px){
    .sub-container{
        width: calc(100% - 4vh);
        padding: 0 2vh;
    }

} 


@media (max-width: 800px){
    .sub-container{
        row-gap: 2vh;
        align-items: center;
        flex-direction: column;
    }

    .text-container{
        width: 100%;
    }

    .slider-contaier{
        width: 100%;
    }
} 

</style>