<template>
    <div class="product-container">
        <Header />
        <Breadcrumbs
            :path="[
                {name: 'Главная', url: '/'},
                {name: 'Каталог', url: '/catalog'},
                {name: category_ru.charAt(0).toUpperCase() + category_ru.slice(1).replace('_' , ' '), url: `/catalog/${category.charAt(0).toLowerCase() + category.slice(1)}`},
                {name: title, url: '/'},
            ]"
        />
        <div>
            <!-- <SkeletonHeaderProduct v-if="title === ''"/> -->
            <HeaderProductLenta :image="imageBase64" :title="title"/>
            <ProductCards/>
            <DescriptionAndObjectives/>
            <NeedToKnowLenta/>
            <!-- <DefectsDetection/> -->
            <OperatorAlert/>
            <PipelineSupport/>
            <SafetyLenta/>
            <SimplificationOfWork/>
            <DataStructuring/>
            <StorageAndReporting/>
            <RecordingTheHstory/>
            <UserSupport/>
            <EaseOfOperation/>
            <CarouselLenta/>

            <DocumentationProductPage
              :title="$store.state.lang === 'ru-RU' ? 'Документация' : 'Documentation'"
              :names="[]"
              :arrayElement="[]"
              :id="productID"
            />
            <ApplicationLenta/>

            <CardCarousel
                    :title="$store.state.lang == 'ru-RU' ? 'РЕКОМЕНДУЕМОЕ ИЗ ТОЙ ЖЕ КАТЕГОРИИ' : 'RECOMMENDED FROM THE SAME CATEGORY'"
                    :cards="recommendedCard.filter(card => card.id != productID)"
                    :link="`/product/`"
                    :category_type="category"
                    :description="''"
                    v-if="recommendedCard.length > 1"
            />

        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import HeaderProductLenta from "@/components/Product/HeaderProductLenta.vue";
import DescriptionProduct from "@/components/Product/DescriptionProduct.vue";
import SpecificationsProduct from "@/components/Product/SpecificationsProduct.vue";
import RecommendedProduct from "@/components/Product/RecommendedProduct.vue";
import {HTTP} from "@/plugins/http-common";
import CardCarousel from "@/components/CardCarousel.vue";
import Parser from "@/components/Parser/Parser.vue";
import SkeletonHeaderProduct from "@/components/Sceletons/SkeletonHeaderProduct.vue";
import SkeletonParser from "@/components/Sceletons/SkeletonParser.vue";
import {useHead} from "@vueuse/head"
import Breadcrumbs from "@/components/ui/Breadcrumbs.vue"
import DocumentationProductPage from "@/components/DocumentationProductPage.vue";
import ProductCards from "@/components/ProductCards.vue";
import DescriptionAndObjectives from "@/components/DescriptionAndObjectives.vue";
import DefectsDetection from "@/components/DefectsDetection.vue";
import OperatorAlert from "@/components/ OperatorAlert.vue";
import SafetyLenta from "@/components/SafetyLenta.vue";
import NeedToKnowLenta from "@/components/NeedToKnowLenta.vue";
import PipelineSupport from "@/components/PipelineSupport.vue";
import SimplificationOfWork from "@/components/SimplificationOfWork.vue";
import DataStructuring from "@/components/DataStructuring.vue";
import StorageAndReporting from "@/components/ StorageAndReporting.vue";
import RecordingTheHstory from "@/components/RecordingTheHstory.vue";
import UserSupport from "@/components/ UserSupport.vue";
import EaseOfOperation from "@/components/EaseOfOperation.vue";
import CarouselLenta from "@/components/Slider/CarouselLenta.vue";
import ApplicationLenta from "@/components/ApplicationLenta.vue";

export default {
    name: "ProductLenta",
    components: {
        DocumentationProductPage,
        SkeletonParser,
        SkeletonHeaderProduct,
        Parser,
        RecommendedProduct,
        SpecificationsProduct,
        DescriptionProduct,
        HeaderProductLenta,
        Footer,
        Header,
        CardCarousel,
        Breadcrumbs,
        ProductCards,
        DescriptionAndObjectives,
        DefectsDetection,
        OperatorAlert,
        SafetyLenta,
        NeedToKnowLenta,
        PipelineSupport,
        SimplificationOfWork,
        DataStructuring,
        StorageAndReporting,
        RecordingTheHstory,
        UserSupport,
        EaseOfOperation,
        CarouselLenta,
        ApplicationLenta
        
    },
    data() {
        return {
            // Header Product
            imageBase64: [],
            header: [],
            title: "",
            titleEN: "",
            characteristicRU: "",
            category: '',
            category_ru: '',
            characteristicEN: "",

            // Description Product
            titleDescription: '',

            description: '',
            keywords: '',

            titleDistinctiveFeatures: '',
            ArrayList: [],

            titleSystemComposition: '',
            SystemComposition: "",

            titleScopeOfApplication: '',
            ScopeOfApplication: '',


            arrayHeaderImageNamesDocument: {
              headerImageBase64Document: [],
              uploadedNamesDocument: []
            },

            //
            recommendedCard: [],

            errorStatus: false
        }
    },
    props: {
        productID: {
            default: '1'
        },
    },

    created() {
        try {
          HTTP.get(`/catalog/get_catalog_by_id/${this.productID}${this.$store.state.lang === 'ru-RU' ? 'ru' : 'eng'}`).then(response => {
            let imagePaths = JSON.parse(response.data?.image).image;

            this.imageBase64 = [];

            const imagePromises = imagePaths.map((imagePath, index) => {
              const formData = new FormData();
              formData.append('image', imagePath);

              return HTTP.post('/get_image/download_image', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                responseType: 'blob'
              }).then(postResponse => {
                const url = URL.createObjectURL(postResponse.data);
                return { url, index };
              });
            });

            Promise.all(imagePromises).then(results => {
              results.sort((a, b) => a.index - b.index);

              this.imageBase64 = results.map(result => result.url);
            });

            for (let i in response.data.body) {
              this.header[i] = response.data.body[i];
            }

            this.title = response.data.title;
            this.category = JSON.parse(response.data.category).category_eng;
            this.category_ru = JSON.parse(response.data.category).category_ru


            this.$watch(
                () => this.productID,
                async () => {
                  this.title = '';
                  this.imageBase64 = [];
                  this.header = [];
                  this.category = '';
                  this.category_ru = '';

                  try {
                    const lang = this.$store.state.lang === 'ru-RU' ? 'ru' : 'eng';
                    const [getResponse] = await Promise.all([
                      HTTP.get(`/catalog/get_catalog_by_id/${this.productID}${lang}`)
                    ]);

                    const responseData = getResponse.data;

                    for (let i in responseData.body) {
                      this.header[i] = responseData.body[i];
                    }

                    imagePaths = JSON.parse(responseData.image).image;
                    this.imageBase64 = [];

                    const imagePromises = imagePaths.map((imagePath, index) => {
                      const formData = new FormData();
                      formData.append('image', imagePath);

                      return HTTP.post('/get_image/download_image', formData, {
                        headers: {
                          'Content-Type': 'multipart/form-data',
                        },
                        responseType: 'blob'
                      }).then(postResponse => {
                        const url = URL.createObjectURL(postResponse.data);
                        return { url, index };
                      });
                    });

                    const results = await Promise.all(imagePromises);

                    results.sort((a, b) => a.index - b.index);

                    this.imageBase64 = results.map(result => result.url);

                    this.title = responseData.title;
                    this.category = JSON.parse(responseData.category).category_eng;
                    this.category_ru = JSON.parse(response.data.category).category_ru

                  } catch (error) {
                    console.error('Ошибка при выполнении запросов:', error);
                    window.location.href = '/404';
                  }

                  document.getElementsByClassName('product-container')[0].scrollTop = 0;
                }
            );

            const categoryType = JSON.parse(response.data?.category_transcription).category_ru.replaceAll(' ', '_');
            const cardName = response.data?.title_transcription.replaceAll(' ', '_');
            useHead({
              title: response.data?.title,
              meta: [
                {
                  name: `description`,
                  content: JSON.parse(response.data?.description)?.description
                },
                {
                  name: `keywords`,
                  content: JSON.parse(response.data?.keywords)?.keywords.join()
                },
                { 
                  property: 'og:title', 
                  content: response.data.title
                },
                { 
                  property: 'og:description', 
                  content: JSON.parse(response.data?.description)?.description
                },
                { 
                  property: 'og:image', 
                  content: JSON.parse(response.data?.image)?.image[0] 
                }, 
                { 
                  property: 'og:url', 
                  content: `${window.location.origin}/catalog/${categoryType}/${cardName}?id=${this.productID}`
                },
                { 
                  property: 'og:type', 
                  content: 'product' 
                }
              ]
            });

            try {
              HTTP.get(`/catalog/get_by_category${this.category}`)
                  .then(response => {
                    Promise.all(
                        response.data.map(item => {
                          let parsedImage = JSON.parse(item.image).image[0];

                          const formData = new FormData();
                          formData.append('image', parsedImage);

                          return HTTP.post('/get_image/download_image', formData, {
                            headers: {
                              'Content-Type': 'multipart/form-data',
                            },
                            responseType: 'blob'
                          }).then(postResponse => {
                            const url = URL.createObjectURL(postResponse.data);
                            
                            return {
                              id: item.id,
                              title: item.title,
                              category: item.category,
                              title_transcription: item.title_transcription,
                              image: url
                            };
                          });
                        })
                    )
                        .then(results => {
                          this.recommendedCard = results;
                        })
                        .catch(err => {
                          console.error(err);
                        });
                  })
                  .catch(err => {
                    console.error(err);
                  });

            } catch (e) {
              console.error(e);
            }

            try {
              HTTP.get(`/catalog/get_pdf_by_id/${this.productID}`).then(response => {
                this.arrayHeaderImageNamesDocument.headerImageBase64Document = JSON.parse(JSON.parse(response.data.pdf).pdf);
              }).catch(err => {
                this.errorStatus = true;
              });
            } catch (e) {
              console.error(e);
            }

            try {
              HTTP.get(`/catalog/get_pdf_title_by_id/${this.productID}`).then(response => {
                this.arrayHeaderImageNamesDocument.uploadedNamesDocument = response.data.title_ru;
              }).catch(err => {
                this.errorStatus = true;
              });
            } catch (e) {
              console.error(e);
            }

          }).catch(err => {
            console.error(err);
            window.location.href = '/404';

          });





        } catch (e) {

        }


    },

    // inheritAttrs: false,


}
</script>

<style scoped>
.product-container {
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
}

</style>