<template>
    <div class="description-container">
        <div class="description-sub-container">
            <h2 class="title">Хранение и отчётность</h2>
            <p class="description">
                Журнал работ хранит в себе и предоставляет подробную информацию о состоянии и истории изменения элементов по каждому из конвейеров шахты по отдельности.
            </p>
            <div class="cards-sub-container">
                <div class="card">
                    <h3 class="card-title">Стыки</h3>
                    <div class="bottom-card">
                        <p class="card-text">
                            Хранит информацию о всех стыках конвейера, включая текущее состояние каждого стыка, его местоположение и другие важные параметры.
                        </p>
                        <video class="card-image"  autoplay loop muted playsinline>
                            <source src="../../lenta_video/стыки.webm" type="video/mp4">
                            Ваш браузер не поддерживает видео.
                        </video>
                    </div>
                </div>

                <div class="card">
                    <h3 class="card-title">Дефекты</h3>
                    <div class="bottom-card">
                        <p class="card-text">
                            Показывает все обнаруженные дефекты конвейера, включая типы дефектов, их расположение и состояние на текущий момент. 
                        </p>
                        <video class="card-image"  autoplay loop muted playsinline>
                            <source src="../../lenta_video/дефекты.webm" type="video/mp4">
                            Ваш браузер не поддерживает видео.
                        </video>
                     </div>
                </div>

                <div class="card">
                    <h3 class="card-title">Отчёты</h3>
                    <div class="bottom-card">
                        <p class="card-text">
                            Просмотр и скачивание отчетов о аспектах работы системы, включая проведенные работы и другие, важные для анализа, параметры.
                        </p>
                        <video class="card-image"  autoplay loop muted playsinline>
                            <source src="../../lenta_video/отчёты.webm" type="video/mp4">
                            Ваш браузер не поддерживает видео.
                        </video>                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: "StorageAndReporting",
  data() {
    return {
     
    }
  },

  methods: {

    
  },
  

}

</script>

<style scoped>

.description-container{
    display: flex;
    width: 100%;
    justify-content: center;
    padding-top: 2vh;
    flex-direction: column;
    align-items: center;
    color: var(--text);
    background: var(--about-bg);


}

.description-sub-container{
    display: flex;
    max-width: 1300px;
    width: 100%;
    flex-direction: column;
    padding-bottom: 4vh;
    align-items: center;
}

.title{
    font-size: clamp(30px, 4.8vw, 50px);
    font-family: geometria_extralight;
    padding-top: 2vh;
    text-transform: uppercase;
    text-align: center;
}

.description{
    text-align: center;
    font-size: clamp(14px, 1.8vh, 50px);
    padding-top: 1vh;
    width: 80%;
}

.card-image{
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 12px;
}

.text{
    font-size: clamp(14px, 1.8vh, 50px);
    padding-top: 3vh;
}

.cards-sub-container{
    display: flex;
    flex-direction: row;
    max-width: 1300px;
    column-gap: 2vh;
    width: 100%;
}

.card{
    display: flex;
    flex-direction: column;
    width: 33%;
    border-radius: 20px;
    color: var(--text);

}

.bottom-card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.card-title{
    font-size: clamp(16px, 2.2vh, 50px);
    font-weight: 600;
    padding-top: 5vh;
    text-align: center;
}

.card-text{
    font-size: clamp(14px, 1.8vh, 50px);
    padding: 3vh 2vh 0 0;
    text-align: center;
    padding-bottom: 2vh;

}

@media (max-width: 1300px){
    .description-sub-container{
        width: calc(100% - 4vh);
        padding: 0 4vh;
    }

} 

@media (max-width: 800px){
    .cards-sub-container{
        flex-direction: column;
        row-gap: 2vh;
    }
    .card{
        width: 100%;
        align-items: center;
    }

    .card-text{
        text-align: center;
    }
} 

</style>