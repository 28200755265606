<template>
    <div class="container">
        <div class="sub-container">
            <div class="text-container">
                <h3 class="title">
                    Обнаружение дефектов и стыков
                </h3>
                <span class="text">
                    Система использует технологии машинного зрения для выявления стыков и различных типов дефектов на полотне конвейера:
                </span>
                <ul class="text-list">
                    <li>Определение продольных и поперечных порывов, пробоин, задиров и латок;</li>
                    <li>Классификация стыков: MATO 38, “Вулкан”, а также вулканизированных стыков;</li>
                    <li>Обнаружение клепок и скоб стыков, контроль их отсутствия;</li>
                    <li>Контроль нагрева ленточного полотна;</li>
                    <li>Измерение скорости движения ленточного конвейера;</li>
                    <li>Построение интерактивной схемы конвейера в веб-интерфейсе;</li>
                    <li>Хранение истории обнаружения дефектов.</li>
                </ul>
            </div>
            <div class="slider-contaier">
                <ImageStackLenta :img1="require('@/assets/img1.png')" :img2="require('@/assets/img2.png')" />

            </div>
        </div>

    </div>  
</template>

<script>
import ImageStackLenta from "@/components/ImageStackLenta.vue";

export default {
  name: "DefectsDetection",
  components: {
        ImageStackLenta
    },
  data() {
    return {
     
    }
  },

  methods: {

    
  },
  

}

</script>

<style scoped>

.container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    background: var(--about-bg);
    padding: 4vh 0;
    color: var(--text);
}

.sub-container{
    display: flex;
    flex-direction: row;
    max-width: 1300px;
    width: 100%;
}

.text-container{
    width: 50%;
}

.title{
    font-size: clamp(16px, 2.2vh, 50px);
    font-weight: 600;
    padding-bottom: 4vh;
}

.text{
    font-size: clamp(14px, 1.8vh, 50px);
}

.text-list{
    font-size: clamp(14px, 1.8vh, 50px);
    padding-left: 3vh;
}

.slider-contaier{
    width: 50%;
    height: 40vh;
}

@media (max-width: 1300px){
    .sub-container{
        width: calc(100% - 4vh);
        margin-left: 2vh;
    }

} 


@media (max-width: 800px){
    .sub-container{
        flex-direction: column;
        row-gap: 2vh;
        align-items: center;
    }

    .text-container{
        width: 100%;
    }

    .slider-contaier{
        width: 60%;
    }
} 

</style>