<template>
    <div v-bind:style="{filter: theme == 'light' ? 'invert(0)' : 'invert(1)'}">
        <div @click="switchLanguage(); changeLanguage()" class="header-lang-switcher">

          {{ language }}
          <svg width="16" height="16" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 0.980392C9.66154 1.92654 5.95563 5.93161 5.87648 11.85M0.65 12.5C0.65 6.29286 5.42245 1.20038 11.4981 0.691753C8.55049 2.13207 5.29923 6.18979 5.22645 11.85H1.96078C1.6018 11.85 1.31078 12.141 1.31078 12.5C1.31078 12.859 1.6018 13.15 1.96078 13.15H5.26376C5.46577 16.1366 6.41966 18.6843 7.67724 20.6287C8.79003 22.3493 10.166 23.6341 11.5062 24.3089C5.42666 23.8041 0.65 18.7099 0.65 12.5ZM8.76884 19.9227C7.64006 18.1775 6.76627 15.8726 6.56698 13.15H17.4766C17.3845 15.9494 16.7022 18.2893 15.758 20.0337C14.7897 21.8229 13.5817 22.9265 12.5046 23.3319C11.3384 22.8833 9.93403 21.7243 8.76884 19.9227ZM17.4778 11.85H6.52652C6.60341 6.33304 9.97565 2.6385 12.5099 1.66716C13.736 2.10339 14.947 3.21309 15.8763 4.98888C16.7817 6.71903 17.3955 9.0417 17.4778 11.85ZM18.7783 11.85C18.6956 8.86182 18.0438 6.32698 17.0281 4.38612C16.1266 2.66335 14.9185 1.37599 13.542 0.695182C19.5986 1.22282 24.35 6.30653 24.35 12.5C24.35 18.708 19.5763 23.801 13.4994 24.3085C14.7762 23.6452 15.9771 22.3602 16.9013 20.6525C17.9543 18.7069 18.6846 16.1524 18.7773 13.15H23.0392C23.3982 13.15 23.6892 12.859 23.6892 12.5C23.6892 12.141 23.3982 11.85 23.0392 11.85H18.7783Z" stroke="white" stroke-width="1.3" stroke-linecap="round"/>
          </svg>
        </div>
    </div>
</template>

<script setup>
    import { useI18n } from "vue-i18n";
    const { t, locale} = useI18n({useScope: 'global'})

    const switchLanguage = () => {
        locale.value == 'en' ? locale.value = 'ru' : locale.value = 'en';
        localStorage.setItem('language', locale.value);
        // this.language == 'RU' ? this.language = 'EN' : this.language = 'RU'
    }
</script>

<script>
    import store from "@/store"
    
    export default {
        data(){
            return {
                language: localStorage.getItem('language')?.toUpperCase() || 'RU',
                dataTheme: JSON.parse(localStorage.getItem('theme')) || false,
            }
        },

        methods: {
            changeLanguage(){
                this.language == 'RU' ? this.language = 'EN' : this.language = 'RU'
                this.language == 'EN' ? store.commit('changeLanguageEn') : store.commit('changeLanguageRu')
            },
        },

        props: {
            theme: {
                type: String,
                default: 'light'
            }
        }
    }
</script>

<style>
    .header-lang-switcher {
        width: 50px;

        background-position: center;
        display: flex;
        align-items: center;
        gap: 5px;
        background-repeat: no-repeat;

        color: white;

        cursor: pointer;

        user-select: none;

    }
</style>